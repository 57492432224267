<template>
    <super-admin-auth-layout>
        <div @click="errorMessage=''" class="mt-4 pb-5">
            <div class="text-center">
                <h5 class="fs-lg-6 mb-3 font-inter-semi-bold">Verify Your Mobile Number</h5>
                <p class="font-inter-medium mb-4 fs--1 ml-2 text-5 text-center">
                    An OTP has been sent to your registered mobile <br>
                    number +91 8697 355 767 <br> <br>
                    <span class="mt-2">Please enter the OTP below to verify your number</span>
                </p>
            </div>
            <s-form>
                <validated-input label="Enter the OTP" placeholder="" type="password"
                                 v-model="model.password" class="text-secondary mb-1 c-form-input"
                                 :disabled="loading" :rules="rules.password"/>
                <span class="text-danger" v-if="errorMessage" v-html="errorMessage"></span>
                <p class="mb-4 text-right font-inter-medium text-5 link fs--1">
                    Not yet received ?
                    <span class="text-secondary ml-2"><router-link to="/">Resend</router-link></span>
                </p>
                <div class="text-center">
                    <btn class="pl-5 py-3 pr-5 font-inter-medium" block text="Login" loading-text="Validating..." size=""
                         :disabled="loading" :loading="loading"/>
                </div>
            </s-form>
        </div>
    </super-admin-auth-layout>
</template>

<script>
import SuperAdminAuthLayout from '@/views/auth/super-admin-auth/SuperAdminAuthPageLayout';
import urls from '@/data/urls';

export default {
    name       : 'SuperAdminVerifyMobile',
    components : { SuperAdminAuthLayout },
    data () {
        return {
            loading      : false,
            loginUrl     : urls.auth.login,
            errorMessage : '',
            model        : {
                email    : '',
                password : ''
            },
            rules : {
                email : {
                    required : true
                },
                password : {
                    required : true
                }
            }
        };
    }
};
</script>

<style scoped>

</style>
